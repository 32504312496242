import React, { Fragment, ReactNode } from 'react';

import { I18nText, FetchItemError, FetchItemsError } from '../../../../types';

import { Permissions } from '../../../../common/permissions';

import { useThirdPersonView } from '../../../../app/hooks/useThirdPersonView';

import { CheckErrorLayout } from '../../../../common/layouts/CheckErrorLayout';
import { CheckPermissionLayout } from '../../../../common/layouts/CheckPermissionLayout';

import { DefaultFooter } from '../../components/footers/DefaultFooter';
import { GlobalNotification } from '../../components/footers/GlobalNotification';
import { WelcomeModal } from '../../components/footers/WelcomeModal';
import { WelcomeInfoModal } from '../../components/footers/WelcomeInfoModal';
import { DownloadManagerCornerModal } from '../../components/footers/DownloadManagerCornerModal';
import { TermsAcceptModal } from '../../../users/components/helpers/TermsAcceptModal';

import { HeadTitle } from '../../../../helpers/HeadTitle';
import { CheckPermissions } from '../../../../helpers/CheckPermissions';

import { UsersPermissions } from '../../../users/usersConstants';

interface MainLayoutProps {
  action: Permissions | string;
  withoutActionError?: boolean;
  children: ReactNode;
  i18nTitle?: I18nText;
  title?: string;
  error?: FetchItemError | FetchItemsError;
}

function MainLayout({
  action,
  withoutActionError,
  i18nTitle,
  title,
  children,
  error
}: MainLayoutProps) {
  const { isThirdPersonView } = useThirdPersonView();

  return (
    <CheckErrorLayout error={error}>
      <CheckPermissionLayout
        action={action}
        appAction={Permissions.MAIN_ACCESS}
        withoutActionError={withoutActionError}
      >
        {i18nTitle ? <HeadTitle i18nTitle={i18nTitle} /> : null}
        {title ? <HeadTitle i18nTitle={title} /> : null}

        <div
          id="main-section"
          className="flex-1 flex flex-col overflow-hidden relative sm:z-0"
        >
          {children}

          <CheckPermissions
            action={UsersPermissions.READ_SELF_GLOBAL_NOTIFICATION}
          >
            <GlobalNotification />
          </CheckPermissions>

          <CheckPermissions action={UsersPermissions.READ_SELF_WELCOME_WINDOW}>
            <WelcomeModal />
          </CheckPermissions>

          <CheckPermissions
            action={UsersPermissions.READ_SELF_WELCOME_INFO_WINDOW}
          >
            <WelcomeInfoModal />
          </CheckPermissions>

          <CheckPermissions
            action={UsersPermissions.READ_SELF_LEGAL_INFORMATION_UPDATE}
          >
            <TermsAcceptModal />
          </CheckPermissions>

          {isThirdPersonView ? null : (
            <Fragment>
              <DownloadManagerCornerModal />
              <DefaultFooter />
            </Fragment>
          )}
        </div>
      </CheckPermissionLayout>
    </CheckErrorLayout>
  );
}

export default MainLayout;
