import React, { useCallback, useState } from 'react';

import { PageNanoID } from '../../../../pages/pagesTypes';

import {
  FETCH_PAGE_SHOW_PAGE_QUERY,
  FetchPageShowPageQueryResponse
} from '../../../../pages/queries/fetchPageShowPage.query';
import { TOGGLE_USER_TERMS_ACCEPTED_AT_QUERY } from '../../../queries/toggleUserTermsAccepted.query';
import {
  FETCH_USER_TERMS_ACCEPTED_AT_QUERY,
  FetchUserTermsAcceptedAtQueryResponse
} from '../../../queries/fetchUserTermsAcceptedAt.query';

import { usePageShowPage } from '../../../../pages/hooks/usePageShowPage';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useToggleUserTermsAccepted } from '../../../hooks/useToggleUserTermsAccepted';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useUser } from '../../../hooks/useUser';

import { SimpleModal } from '../../../../../helpers/modals/SimpleModal';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../../helpers/Translate';
import { CheckBoxField } from '../../../../../helpers/FormFields/CheckBoxField';

import { PageCache } from '../../../../pages/PageCache';
import { UserCache } from '../../../UserCache';

import { LEGAL_INFORMATION_UPDATE_PAGE_NANOID } from '../../../../../config';

import { stringsKeys, usersKeys, words } from '../../../../../locales/keys';

function TermsAcceptModal() {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  const currentUser = useCurrentUser();

  const { userError } = useUser<FetchUserTermsAcceptedAtQueryResponse>({
    cacheKey: UserCache.userTermsAcceptedAtCacheKey(),
    uuid: currentUser.nanoId,
    query: FETCH_USER_TERMS_ACCEPTED_AT_QUERY,
    options: {
      onSuccess: (data) => {
        if (data?.user) {
          setIsNotificationVisible(!data.user.termsAcceptedAt);
        }
      }
    }
  });

  const {
    toggleUserTermsAccepted,
    toggleUserTermsAcceptedErrorMessage,
    toggleUserTermsAcceptedLoading
  } = useToggleUserTermsAccepted({
    query: TOGGLE_USER_TERMS_ACCEPTED_AT_QUERY,
    cacheKeys: [UserCache.userTermsAcceptedAtCacheKey()]
  });

  const [isRead, setIsRead] = useState(false);

  const { page, pageLoading } = usePageShowPage<FetchPageShowPageQueryResponse>(
    {
      uuid: LEGAL_INFORMATION_UPDATE_PAGE_NANOID as PageNanoID,
      query: FETCH_PAGE_SHOW_PAGE_QUERY,
      cacheKey: PageCache.showPageCacheKey(
        LEGAL_INFORMATION_UPDATE_PAGE_NANOID as PageNanoID
      ),
      initFetch: true
    }
  );

  useShowToastOnErrorChange({
    error: toggleUserTermsAcceptedErrorMessage || userError
  });

  const handleClose = useCallback<() => void>(async () => {
    await toggleUserTermsAccepted({ uuid: currentUser.uuid });

    setIsNotificationVisible(false);
  }, [currentUser.uuid, toggleUserTermsAccepted]);

  const handleHide = useCallback<() => void>(() => {
    setIsNotificationVisible(true);
  }, [setIsNotificationVisible]);

  const toggleIsRead = useCallback<() => void>(() => {
    setIsRead((prevValue) => !prevValue);
  }, []);

  if (!isNotificationVisible) {
    return null;
  }

  return (
    <SimpleModal
      hideModal={handleHide}
      handleSubmit={handleClose}
      id="TermsAcceptModal"
      isOpen={isNotificationVisible}
      withoutCancelButton
      i18nSubmitText={words.accept}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      modalSize="3xl"
      isLoading={toggleUserTermsAcceptedLoading}
      submitDisabled={!isRead}
    >
      <div className="flex justify-center items-center border-b border-gray-300 dark:border-gray-700 py-4">
        <h3 className="text-base sm:text-lg text-center flex-1 truncate">
          <Translate id={usersKeys.legalInformationUpdate} />
        </h3>
      </div>
      <LoadingSkeleton loaded={!pageLoading}>
        <div className="p-4">
          <span
            dangerouslySetInnerHTML={{
              __html: page?.localizedBody
            }}
          />

          <div className="flex space-x-4">
            <CheckBoxField
              id="isRead"
              name="isRead"
              checked={isRead}
              onChange={toggleIsRead}
              disabled={toggleUserTermsAcceptedLoading}
            />
            <span>
              <Translate
                id={stringsKeys.iHaveReadAndAgreeToTheUpdatedInformation}
              />
            </span>
          </div>
        </div>
      </LoadingSkeleton>
    </SimpleModal>
  );
}

export default TermsAcceptModal;
