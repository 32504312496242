import React, { useCallback } from 'react';

import { PageNanoID } from '../../../../pages/pagesTypes';

import {
  FETCH_PAGE_SHOW_PAGE_QUERY,
  FetchPageShowPageQueryResponse
} from '../../../../pages/queries/fetchPageShowPage.query';

import { useReactQueryState } from '../../../hooks/base/reactQuery/useReactQueryState';
import { usePageShowPage } from '../../../../pages/hooks/usePageShowPage';

import { SimpleModal } from '../../../../../helpers/modals/SimpleModal';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../../helpers/Translate';

import { PageCache } from '../../../../pages/PageCache';

import { words } from '../../../../../locales/keys';

import { CommonCache } from '../../../commonCache';

import { WELCOME_PAGE_NANOID } from '../../../../../config';

function WelcomeModal() {
  const { setValue: setIsNotificationVisible, value: isNotificationVisible } =
    useReactQueryState<boolean>(CommonCache.welcomeNotificationCacheKey());

  const { page, pageLoading } = usePageShowPage<FetchPageShowPageQueryResponse>(
    {
      uuid: WELCOME_PAGE_NANOID as PageNanoID,
      query: FETCH_PAGE_SHOW_PAGE_QUERY,
      cacheKey: PageCache.showPageCacheKey(WELCOME_PAGE_NANOID as PageNanoID),
      initFetch: true
    }
  );

  const handleClose = useCallback<() => void>(() => {
    setIsNotificationVisible(false);
  }, [setIsNotificationVisible]);

  const handleHide = useCallback<() => void>(() => {
    setIsNotificationVisible(true);
  }, [setIsNotificationVisible]);

  if (!isNotificationVisible) {
    return null;
  }

  return (
    <SimpleModal
      hideModal={handleHide}
      handleSubmit={handleClose}
      id={CommonCache.welcomeNotificationCacheKey()}
      isOpen={isNotificationVisible}
      withoutCancelButton
      i18nSubmitText={words.close}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      modalSize="3xl"
    >
      <div className="flex justify-center items-center border-b border-gray-300 dark:border-gray-700 py-4">
        <h3 className="text-base sm:text-lg text-center flex-1 truncate">
          <Translate id={words.welcomeToArchivizer} />
        </h3>
      </div>
      <LoadingSkeleton loaded={!pageLoading}>
        <div className="p-4">
          <span
            dangerouslySetInnerHTML={{
              __html: page?.localizedBody
            }}
          />
        </div>
      </LoadingSkeleton>
    </SimpleModal>
  );
}

export default WelcomeModal;
