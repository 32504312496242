import { useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import size from 'lodash/size';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';

import { ErrorMessage } from '../../../../../../../types';
import { InfiniteIndexQueryOnSuccess } from '../../../../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';
import { MessageUUID } from '../../../../../../messages/messagesTypes';

import {
  CREATE_MESSAGE_IN_PROJECT_QUERY,
  CreateMessageInProjectQueryResponse
} from '../../../../../../messages/queries/createMessageInProject.query';

import {
  useCreateMessageInProject,
  CreateMessageInProjectInput
} from '../../../../../../messages/hooks/useCreateMessageInProject';

import {
  useCreateMockMessageWithFormData,
  CreateMockMessageWithFormDataProject,
  CreateMockMessageWithFormDataOptions
} from '../../../../../../messages/components/list/ItemMessagesList/hooks/useCreateMockMessageWithFormData';

import { ItemCreateMessageFormSubmit } from '../../../../../../messages/components/forms/ItemCreateMessageForm';
import { ItemMessagesListMessage } from '../../../../../../messages/components/list/ItemMessagesList';

import { parseRequestError } from '../../../../../../../utils/parseRequestError';

interface ProjectMessagesContentCreateMessageOptions {
  project?: CreateMockMessageWithFormDataProject;
  onAfterCreateMessage?: () => void;
}

function useProjectMessagesContentCreateMessage<
  FetchProjectMessagesQueryResponse
>({
  project,
  onAfterCreateMessage
}: ProjectMessagesContentCreateMessageOptions) {
  const [sendingMessages, setSendingMessages] = useState<
    ItemMessagesListMessage[]
  >([]);

  const handleRemoveSendingMessage = useCallback<
    (messageUuid: MessageUUID) => void
  >((messageUuid) => {
    setSendingMessages((prev) => {
      return remove(
        prev,
        (sendingMessage) => sendingMessage.uuid !== messageUuid
      );
    });
  }, []);

  const { createMessageInProject, createMessageInProjectLoading } =
    useCreateMessageInProject<CreateMessageInProjectQueryResponse>({
      projectNanoId: project?.nanoId,
      query: CREATE_MESSAGE_IN_PROJECT_QUERY
    });

  const handleMarkSendingMessageFailed = useCallback<
    (messageUuid: MessageUUID, errorMessage: ErrorMessage) => void
  >((messageUuid, errorMessage) => {
    setSendingMessages((prev) => {
      const failedMessageIndex = findIndex(
        prev,
        (sendingMessage) => sendingMessage.uuid === messageUuid
      );

      if (failedMessageIndex === -1) {
        return prev;
      } else {
        const newSendingMessages = [...prev];
        newSendingMessages[failedMessageIndex] = {
          ...prev[failedMessageIndex],
          errorMessage
        };
        return newSendingMessages;
      }
    });
  }, []);

  const createMockMessageFromFormData = useCreateMockMessageWithFormData();

  const handleCreateMessageInProject = useCallback<ItemCreateMessageFormSubmit>(
    async (data) => {
      const newSendingMessage = createMockMessageFromFormData({
        formData: data,
        project
      } as CreateMockMessageWithFormDataOptions);

      setSendingMessages((prev) => [...prev, newSendingMessage]);

      try {
        const result = await createMessageInProject({
          ...data,
          projectId: project?.id
        } as CreateMessageInProjectInput);

        onAfterCreateMessage?.();

        return result;
      } catch (err) {
        const errorMessage = parseRequestError(err);
        handleMarkSendingMessageFailed(data.uuid as MessageUUID, errorMessage);
        throw err;
      }
    },
    [
      project,
      createMessageInProject,
      setSendingMessages,
      handleMarkSendingMessageFailed,
      onAfterCreateMessage,
      createMockMessageFromFormData
    ]
  );

  const handleFetchMessagesSuccess = useCallback<
    InfiniteIndexQueryOnSuccess<FetchProjectMessagesQueryResponse>
  >((data) => {
    const lastMessages = data?.pages?.[0]?.['messages']?.nodes;
    if (isArray(lastMessages) && !isEmpty(lastMessages)) {
      setSendingMessages((prev) => {
        const filteredSendingMessages = filter(
          prev,
          (sendingMessage) => !find(lastMessages, ['uuid', sendingMessage.uuid])
        );

        return size(prev) === size(filteredSendingMessages)
          ? prev
          : filteredSendingMessages;
      });
    }
  }, []);

  return {
    createMessageInProjectLoading,
    sendingMessages,
    handleCreateMessageInProject,
    handleFetchMessagesSuccess,
    handleRemoveSendingMessage
  };
}

export default useProjectMessagesContentCreateMessage;
